/* App.css */
body {
  font-family: "Roboto", sans-serif;
}

#BasicSalaryHideInputText, #AllowancesHideInputText {
  -webkit-text-security: disc!important;
}

/* .hidden-text {
  -webkit-text-security: none;
} */


